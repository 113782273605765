import React from "react";
import { circleAnim, verticalAnimTop } from "../Animations";
import { motion, AnimatePresence } from "framer-motion";
import useStore from "../Store";

type Show = {
  name: string;
  place: string;
  date: string;
  link?: string;
};

const SHOWS: Show[] = [
  {
    name: "Besloten feest",
    place: "Eindhoven",
    date: "11 april",
  },
  {
    name: "Paaspop",
    place: "Schijndel",
    date: "17 april",
  },
  {
    name: "Paaspop",
    place: "Schijndel",
    date: "20 april",
  },
];

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

export default function FrinoShows() {
  const lang = useStore((state) => state.lang);
  const setShowMailPopup = useStore((state) => state.setShowMailPopup);

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: "/video/kungfu_comp2.mp4",
        type: "video/mp4",
      },
    ],
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={circleAnim}
      transition={{ duration: 0.6 }}
      className="w-screen bg-cover relative z-10 overflow-hidden min-h-screen px-4"
      style={{
        backgroundImage: `url('/img/grindtegelbg.webp')`,
        backgroundPosition: "43% 30%",
      }}
    >
      <div className="absolute w-full h-full inset-0 bg-white bg-opacity-75 z-0"></div>

      <div className="mx-auto relative pt-32 sm:pb-24 pb-12 z-10 flex flex-col items-center">
        <h2 className="sm:mb-6 sm:text-6xl text-center">
          {lang === "br" ? "Tetsen krijge?" : "Aanstaande optredens"}
        </h2>
        <h3>{lang === "br" ? "Kome dan" : "Wees welkom!"}</h3>
      </div>
      <span
        style={{ maxWidth: "800px" }}
        className=" mx-auto relative z-10 w-full block text-center text-xl lg:text-2xl"
      >
        <div className="h-6"></div>
        {SHOWS.map((show: any, index: number) => (
          <Show index={index} />
        ))}
      </span>

      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={verticalAnimTop}
        transition={{ duration: 0.6, delay: 2, type: "spring" }}
        className="absolute w-screen h-full "
      >
        <img
          src="/img/ruben2.png"
          className="w-36 md:w-60 xl:w-80 -bottom-20 md:-bottom-40 absolute md:-right-16 -right-4 opacity-75 md:opacity-100"
        ></img>
      </motion.div>
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={verticalAnimTop}
        transition={{ duration: 0.6, delay: 2.8, type: "spring" }}
        className="absolute w-screen h-full "
      >
        <img
          src="/img/koen2.png"
          className="w-24 md:w-40 xl:w-52 -bottom-10 md:-bottom-24 absolute md:-left-8 -left-4 opacity-75 sm:opacity-100"
        ></img>
      </motion.div>
    </motion.div>
  );
}

const Show = ({ index }: any) => {
  const setPointer = useStore((state) => state.setPointer);

  return SHOWS[index].link ? (
    <a
      onMouseEnter={() => setPointer(true)}
      onMouseLeave={() => setPointer(false)}
      className="flex flex-col md:flex-row w-full justify-between items-center mb-8 transform sm:hover:scale-110"
      href={SHOWS[index].link}
      target="_blank"
    >
      <h3 className="md:w-1/3 sm:text-2xl text-3xl  lg:text-3xl  sm:text-left text-center ">
        {SHOWS[index].name}
      </h3>
      <h3 className="md:w-1/3 sm:text-2xl lg:text-3xl text-center ">
        {SHOWS[index].place}
      </h3>
      <h3 className="md:w-1/3 sm:text-2xl lg:text-3xl text-xl sm:text-right text-center ">
        {SHOWS[index].date}
      </h3>
    </a>
  ) : (
    <div
      onMouseEnter={() => setPointer(true)}
      onMouseLeave={() => setPointer(false)}
      className="flex flex-col md:flex-row w-full justify-between items-center mb-8 transform sm:hover:scale-110"
    >
      <h3 className="md:w-1/3 sm:text-2xl text-3xl  lg:text-3xl  sm:text-left text-center ">
        {SHOWS[index].name}
      </h3>
      <h3 className="md:w-1/3 sm:text-2xl lg:text-3xl text-center ">
        {SHOWS[index].place}
      </h3>
      <h3 className="md:w-1/3 sm:text-2xl lg:text-3xl text-xl sm:text-right text-center ">
        {SHOWS[index].date}
      </h3>
    </div>
  );
};
